import React from 'react';
import PostSummaryList from '../PostSummary/index.js';

const ExploreComponent = () => {
  return (
    <>
      <div className='row'>
        <div className='col-11 d-inline-block mw-100' style={{
          backgroundColor: 'white',
          borderRadius: '25px'
        }}>
          <div className='d-inline-block' >
            <i className='fas fa-search m-2'></i>
          </div>
          <div className='d-inline-block align-middle'>
            <input type='text' placeholder='Search Twitter' style={{
              border: 'none',
              background: 'none'
            }} />
          </div>
        </div>
        <div className='float-end col-1'>
          <i className='fas fa-cog d-inline text-primary' style={{
            fontSize: '1.5em'
          }}></i>
        </div>
      </div>

      <ul className='nav nav-tabs mt-2'>
        <li className='nav-item'>
          <a className='nav-link active' href='for-you.html'>For you</a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' href='trending.html'>Trending</a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' href='news.html'>News</a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' href='sports.html'>Sports</a>
        </li>
        <li className='nav-item d-sm-none d-md-block'>
          <a className='nav-link' href='entertainment.html'>Entertainment</a>
        </li>
      </ul>

      <div className='card bg-dark text-white border-0'>
        <img className='card-img' src='/images/starship_2.jpg' alt='a spaceX starship' />
        <div className='card-img-overlay d-flex flex-column justify-content-end h-100'>
          <h4 className='card-title fw-bold'>SpaceX's Starship</h4>
        </div>
      </div>
      <PostSummaryList />
    </>
  );
};

export default ExploreComponent