import React from 'react';
import whoToFollow from './who.json'
import WhoToFollowListItem from './WhoToFollowListItem';

const WhoToFollowList = () => {
  return (
    <ul className='list-group w100'>
      <div className='d-inline list-group-item'>
        <span className='fw-bold'>Who To Follow</span>
      </div>
      {
        whoToFollow.map((user) => <WhoToFollowListItem key={JSON.stringify(user)} who={user} />)
      }
    </ul>
  );
}

export default WhoToFollowList;