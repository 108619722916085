import React from 'react'

const PostSummaryItem = ({
  post = {
    topic: 'Test',
    userName: 'JavaScript',
    time: '1 day',
    title: 'JavaScript is programming language that can run on browsers as well as desktops',
    image: {
      src: '/images/javascript.jpg',
      alt: 'the javascript logo',
    },
    tweets: '123K'
  }
}) => {
  return (
    <li className='list-group-item'>
      <div className='row'>
        <div className='col-10'>
          <span style={{
            color: 'gray'
          }}>
            {post.topic}
          </span>
          <div className='d-block'>
            <span className='d-inline-block fw-bold'>
              {post.userName}
            </span>
            <span className='fa-stack align-baseline' style={{
               fontSize: '0.5em',
               paddingRight: '25px'
               }}>
              <i className='fas fa-circle fa-stack-2x'></i>
              <i className='fas fa-check fa-stack-1x fa-inverse'></i>
            </span>
            <span className='fw-light' style={{
              color: 'gray'
            }}>
              - {post.time}
            </span>
          </div>
          <div>
            <span className='fw-bold'>
              {post.title}
            </span>
          </div>
          <div>
            {post.tweets}
          </div>
        </div>
        <div className='col-2'>
          <img src={post.image.src} alt={post.image.alt} className='w-100 rounded align-middle' />
        </div>
      </div>
    </li >
  );
}

export default PostSummaryItem