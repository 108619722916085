import posts from './data/tweets.json'
// import the data from the JSON file

const initialState = {
  tweets: posts
};

const tweets = (state = initialState, action) => {
  switch (action.type) {
    case 'create-tweet':
      const tweet = {
        _id: (new Date()).getTime() + '',
        "topic": "Web Development",
        "userName": "ReactJS",
        "verified": false,
        "handle": "ReactJS",
        "time": "2h",
        tweet: action.tweet,
        "avatar-image": "/images/react.jpg",
        "logo-image": "/images/react.jpg",
        "stats": {
          "comments": 123,
          "retweets": 234,
          "likes": 345
        },
      };
      return ({
        tweets: [
          tweet,
          ...state.tweets,
        ]
      });
    case 'fetch-all-tweets':
      return ({
        tweets: action.tweets
      })

    case 'delete-tweet':
      return ({
        tweets: state.tweets
          .filter(tweet => tweet._id !== action.tweet._id)
      });
    case 'like-tweet':
      const newTweets = state.tweets.map(tweet => {
        if (tweet._id === action.tweet._id) {
          if (tweet.liked === true) {
            tweet.liked = false;
            tweet.stats.likes--;
          } else {
            tweet.liked = true;
            tweet.stats.likes++;
          }
        }
        return tweet;
      });

      return ({ tweets: newTweets })

    default:
      return (state)
  }
};

export default tweets;