import React from 'react';
import ExploreScreen from './ExploreScreen';

const Build = () => {
  return (
    <ExploreScreen />
  )
};

export default Build;
