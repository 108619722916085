import React from "react";
import { useSelector } from "react-redux";
import TweetListItem from "./TweetListItem";

const getAllTweets = (state) => state.tweets.tweets

const TweetList = () => {
  const tweets = useSelector(getAllTweets);
  return (
    <>
      <ul className="list-group">
        {
          tweets.map(tweet => {
            return <TweetListItem tweet={tweet} />
          })
        }
      </ul>
    </>
  )
};

export default TweetList;
