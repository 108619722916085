import React from 'react';
import PostSummaryItem from './PostSummaryItem.js';
import exploreItems from './posts.json'

const PostSummaryList = () => {
  return (
    <ul className='list-group w-100'>
      {exploreItems.map((item) => <PostSummaryItem key={JSON.stringify(item)} post={item} />)}
    </ul>
  )
};

export default PostSummaryList