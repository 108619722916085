const URL = 'http://127.0.0.1:4000/rest/movies';

export const findAllMovies = () =>
  fetch(URL)
    .then(response => response.json());


export const deleteMovie = (id) => {
  return fetch(`${URL}/${id}`, {
    method: 'DELETE',
  });
}

export const createMovie = (movie) => {
  return fetch(URL, {
    method: 'POST',
    body: JSON.stringify(movie),
    headers: {
      'content-type': 'application/json'
    }
  }).then(response => response.json());
};

export const findMovieById = (id) => {
  console.log(id);
  return fetch(`${URL}/${id}`)
    .then(response => response.json());
}

export const updateMovie = (movie) => {
  return fetch(`${URL}/${movie._id}`, {
    method: 'PUT',
    body: JSON.stringify(movie),
    headers: {
      'content-type': 'application/json'
    },
  }).then(response => response.json());
}

const exportObject = {
  findAllMovies,
  deleteMovie,
  createMovie,
  findMovieById,
  updateMovie,
}

export default exportObject;
