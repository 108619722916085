import React from "react";
import HelloApiClient from "./HelloApiClient";
import MovieApiClient from "./MovieApiClient";

const APIExamples = () => {
  return (
    <div>
      <h2>API Examples</h2>
      <MovieApiClient />
      <HelloApiClient />
    </div>
  );
};

export default APIExamples;