import React from 'react';
import PostSummaryItem from './PostSummaryItem.js';
import { useSelector } from 'react-redux';

const selectAllPosts = (state) => state.tweets.tweets;

const PostSummaryList = ({ title }) => {
  const posts = useSelector(selectAllPosts)
  return (
    <ul className="list-group">
      {
        title &&
        <li className="list-group-item">
          <h5 className="fw-bolder">{title}</h5>
        </li>
      }
      {posts.map((item) => <PostSummaryItem key={JSON.stringify(item)} post={item} />)}
    </ul>
  )
};

export default PostSummaryList