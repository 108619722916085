import React from 'react'

const PostSummaryItem = ({
  post = {
    "_id": "123",
    "topic": "Web Development",
    "userName": "ReactJS",
    "verified": false,
    "handle": "ReactJS",
    "time": "2h",
    "title": "React.js is a component based front end library that makes it very easy to build Single Page Applications or SPAs",
    "tweet": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "attachments": {
      "video": "unKvMC3Y1kI"
    },
    "logo-image": "/images/react-blue.png",
    "avatar-image": "/images/react-blue.png",
    "stats": {
      "comments": 123,
      "retweets": 234,
      "likes": 345
    }
  }
}) => {
  return (
    <li className='list-group-item'>
      <div className='row'>
        <div className='col-10'>
          <span style={{
            color: 'gray'
          }}>
            {post.topic}
          </span>
          <div className='d-block'>
            <span className='d-inline-block fw-bold'>
              {post.userName}
            </span>
            {
              post.verified &&
              <span className='fa-stack align-baseline' style={{
                fontSize: '0.5em',
                paddingRight: '25px'
              }}>
                <i className='fas fa-circle fa-stack-2x'></i>
                <i className='fas fa-check fa-stack-1x fa-inverse text-dark'></i>
              </span>
            }

            <span className='fw-light' style={{
              color: 'gray'
            }}>
              - {post.time}
            </span>
          </div>
          <div>
            <span className='fw-bold'>
              {post.title}
            </span>
          </div>
          <div>
            {post.tweets}
          </div>
        </div>
        <div className='col-2'>
          <img src={post['logo-image']} alt={post.title} className='w-100 rounded align-middle' />
        </div>
      </div>
    </li >
  );
}

export default PostSummaryItem