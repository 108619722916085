import whoJson from './data/who.json'
// import the data from the JSON file

const initialState = {
  who: whoJson
};

const who = (state = initialState, action) => {  // initialize the reducer's state
  return(state)  //return the single static state
};

export default who;