import React from 'react';
import { Link } from 'react-router-dom'

const NavigationSidebar = ({
  active = 'explore'
}) => {
  const components = [
    {
      name: '',
      url: '/',
      faIcon: <i className='fab fa-twitter'></i>
    },
    {
      name: 'Home',
      url: '/a8/twitter/home',
      faIcon: <i className='fas fa-home align-baseline'></i>
    },
    {
      name: 'Explore',
      url: '/a8/twitter/explore',
      faIcon: <i className='fas fa-hashtag align-baseline'></i>
    },
    {
      name: 'Notifications',
      url: '',
      faIcon: <i className='fas fa-bell align-baseline'></i>
    },
    {
      name: 'Messages',
      url: '',
      faIcon: <i className='fas fa-envelope align-baseline'></i>
    },
    {
      name: 'Bookmarks',
      url: '',
      faIcon: <i className='fas fa-bookmark align-baseline'></i>
    },
    {
      name: 'Lists',
      url: '',
      faIcon: <i className='fas fa-list align-baseline'></i>
    },
    {
      name: 'Profile',
      url: '',
      faIcon: <i className='fas fa-user align-baseline'></i>
    },
    {
      name: 'More',
      url: '',
      faIcon:
        <span className='fa-stack align-baseline' style={{ 'fontSize': '0.5em' }}>
          <i className='fas fa-circle fa-stack-2x'></i>
          <i className='fas fa-ellipsis-h fa-stack-1x fa-inverse'></i>
        </span >
    },

  ]
  return (
    <>
      <ul className='list-group w-100 float-end d-inline'>
        {
          components.map((component) => {
            return (
              <Link to={component.url}
                className={
                  `list-group-item mx-auto 
                  ${active.toLowerCase() === component.name.toLowerCase() ? 'active' : ''} `
                }>
                <div className='d-inline-block px-2'>
                  <div className='d-flex justify-content-center'>
                    {component.faIcon}
                  </div>
                </div>
                <div className='d-xl-inline-block d-sm-none'>
                  {component.name}
                </div>
              </Link>
            )
          }
          )}
      </ul>
      <a href='./#' type='button' className='btn btn-primary w-100 float-end mt-2 rounded-pill d-inline'>Tweet</a>
    </>
  );
}
export default NavigationSidebar;
