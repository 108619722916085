import React, { useState } from "react";
import { useDispatch } from "react-redux";

const WhatsHappening = () => {
  let [whatsHappening, setWhatsHappening] = useState('');
  const dispatch = useDispatch();
  const tweetClickHandler = () => {
    dispatch({
      type: 'create-tweet',
      tweet: whatsHappening
    });
  }
  return (
    <div className="d-inline-block w-100">
      <textarea className="form-control bg-black text-white" placeholder="What's happening?" value={whatsHappening} onChange={(event) =>
      setWhatsHappening(event.target.value)}>
      </textarea>

      <button className="mt-2 mb-2 btn btn-primary rounded-pill float-end" onClick={tweetClickHandler}>
        Tweet
      </button>
    </div>
  );
};

export default WhatsHappening