import { BrowserRouter, Route, Link } from 'react-router-dom'
import './vendors/bootstrap/css/bootstrap.min.css';
import './vendors/bootstrap/bootstrap.min.css';
import './vendors/fontawesome/css/all.min.css';

import BuildA6 from './components/a6/Build'
import HelloA6 from './components/a6/HelloWorld'
import BuildA7 from './components/a7/Build'
import BuildA8 from './components/a8/Build'
import PracticeA6 from './components/a6/Practice'
import Practice7 from './components/a7/Practice'
import Practice8 from './components/a8/Practice'

import A6 from './components/a6'
import A7 from './components/a7';
import A8 from './components/a8'
import A9 from './a9';


function App() {
  return (
    <BrowserRouter>
      <div className="container">
        <Link to="/a6/practice">A6</Link> |
        <Link to="/a7/practice">A7</Link> |
        <Link to="/a8/practice">A8</Link> |
        <Link to="/a9/practice">A9</Link>

        <Route path={['/a8']} exact={true}>
          <A8 />
        </Route>
        <Route path={['/a7']} exact={true}>
          <A7 />
        </Route>
        <Route path={['/a6']} exact={true}>
          <A6 />
        </Route>
        <Route path={["/a8/practice"]} exact={true}>
          <Practice8 />
        </Route>
        <Route path={["/a7/practice"]} exact={true}>
          <Practice7 />
        </Route>
        <Route path="/a6/hello" exact={true}>
          <HelloA6 />
        </Route>
        <Route path={["/a6/practice"]} exact={true}>
          <PracticeA6 />
        </Route>
        <Route path={['/a6/twitter/explore']} exact={true}>
          <BuildA6 />
        </Route>
        <Route path={['/a7/twitter/explore', '/a7/twitter/home']} exact={true}>
          <BuildA7 />
        </Route>
        <Route path={['/a8/twitter/explore', '/a8/twitter/home']} exact={true}>
          <BuildA8 />
        </Route>
        <Route path="/a9">
          <A9 />
        </Route>


      </div>
    </BrowserRouter>
  );
};


export default App;
