import React from 'react';
import A6 from './index';
import History from '../history';

const HelloWorld = () => {
  return (
      <div>
        <h1>Hello World!</h1>
        <A6/>
      	<hr />
        <History />
      </div>
  )
};

export default HelloWorld;