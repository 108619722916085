import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TweetListItem from "./TweetListItem";
import { fetchAllTweets } from '../../../../services/twitterService';

const getAllTweets = (state) => state.tweets.tweets

const TweetList = () => {
  const tweets = useSelector(getAllTweets);
  const dispatch = useDispatch();
  useEffect(() => fetchAllTweets(dispatch));
  return (
    <>
      <ul className="list-group">
        {
          tweets.map(tweet => {
            return <TweetListItem tweet={tweet} />
          })
        }
      </ul>
    </>
  )
};

export default TweetList;
