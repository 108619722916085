import React from 'react';
import { Link } from 'react-router-dom';
import Movies from './Movies';


const Practice = () => {
  return (
    <>
      <span style={{ color: 'gray' }}>
        A9&nbsp;&nbsp;
      </span>
      <Link to='/a9/twitter/home'>
        Build
      </Link>
      <h1>Practice</h1>
      <Movies />
    </>
  )
};

export default Practice;