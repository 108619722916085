import React from 'react'
import TweetStats from './Stats';
import { useDispatch } from 'react-redux';

const TweetListItem = ({
  tweet = {
    "_id": "123",
    "topic": "Web Development",
    "userName": "ReactJS",
    "verified": false,
    "handle": "ReactJS",
    "time": "2h",
    "title": "React.js is a component based front end library that makes it very easy to build Single Page Applications or SPAs",
    "tweet": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "attachments": {
      "video": "unKvMC3Y1kI"
    },
    "logo-image": "/images/react-blue.png",
    "avatar-image": "/images/react-blue.png",
    "stats": {
      "comments": 123,
      "retweets": 234,
      "likes": 345
    }
  }
}) => {
  const dispatch = useDispatch();
  const deleteTweetClickHandler = () => {
    dispatch({ type: 'delete-tweet', tweet })
  };

  return (
    <li className='list-group-item'>
      <i onClick={deleteTweetClickHandler}
        className="fa fa-remove fa-pull-right"></i>
      <div className='row'>
        <div className='col-2 col-md-1'>
          <img src={tweet['avatar-image']} alt={tweet.title} className='w-100 rounded-circle align-middle' />
        </div>
        <div className='col-10 col-md-11'>
          <div>
            <span className='fw-bold'>
              {tweet.userName}
            </span>
            <span className="fw-light" style={{ color: 'gray' }}>
              &nbsp;&nbsp;@{tweet.handle}
            </span>
            {
              tweet.verified &&
              <span className='fa-stack align-baseline' style={{
                fontSize: '0.5em',
                paddingRight: '25px'
              }}>
                <i className='fas fa-circle fa-stack-2x'></i>
                <i className='fas fa-check fa-stack-1x fa-inverse text-dark'></i>
              </span>
            }
          </div>
          <p>{tweet.tweet}</p>
          {
            tweet.attachments && tweet.attachments.image &&
            <img src={tweet.attachments.image}
              className="mt-2 wd-border-radius-20px"
              style={{ width: "100%" }} alt={tweet.tweet} />
          }

        </div>
        <div className="flex justify-content-center align-items-center">
          <TweetStats tweet={tweet} />
        </div>
      </div>


    </li >
  );
}

export default TweetListItem