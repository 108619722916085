import React from 'react'

import { Route } from "react-router-dom"
import HomeScreen from "./HomeScreen"
import ExploreScreen from './ExploreScreen'
import who from '../../../reducers/who'
import { combineReducers, createStore } from 'redux'
import { Provider } from 'react-redux'
import tweets from '../../../reducers/tweets'


const reducers = combineReducers({who, tweets})
const store = createStore(reducers);

const Build = () => {
  return (
    <Provider store={store}>
      <Route path='/a7/twitter/home' exact={true}>
        <HomeScreen />
      </Route>
      <Route path='/a7/twitter/explore' exact={true}>
        <ExploreScreen />
      </Route>
    </Provider>
  );
};

export default Build;