import React from 'react';

const WhoToFollowListItem = ({
  who = {
    avatarIcon: '/images/NASA.jpg',
    verified: true,
    userName: 'NASA',
    handle: 'NASA',
  }
}) => {
  return (
    <li className='list-group-item'>
      <div className='row'>
        <div className='col-2 rounded-circle'>
          <img src={who.avatarIcon} alt={`An avatar for ${who.handle}'s twitter account`} className='w-100 rounded-pill' />
        </div>
        <div className='col-6'>
          <div className='d-block'>
            <a href='./#' className='d-inline-block fw-bold text-decoration-none text-reset '>
              {who.userName}
            </a>
            {
              who.verified &&
              <span className='fa-stack align-baseline' style={{ 'fontSize': '0.5em' }}>
                <i className='fas fa-circle fa-stack-2x'></i>
                <i className='fas fa-check fa-stack-1x fa-inverse text-dark'></i>
              </span>
            }

          </div>
          <span className='wd-color-gray fw-light display-block'>
            @{who.handle}
          </span>
        </div>
        <div className='col-4'>
          <button type='button' className='btn btn-primary w-100 rounded-pill d-inline align-baseline'>Follow</button>
        </div>
      </div>
    </li >
  )
};

export default WhoToFollowListItem;