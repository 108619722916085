import React from 'react';
import WhoToFollowListItem from './WhoToFollowListItem';
import { useSelector } from 'react-redux';

const selectAllWho = (state) => state.who.who; 

const WhoToFollowList = () => {
  const who = useSelector(selectAllWho);
  return (
    <ul className='list-group w100'>
      <div className='d-inline list-group-item'>
        <span className='fw-bold'>Who To Follow</span>
      </div>
      {
        who.map((user) => <WhoToFollowListItem key={user.userName} who={user} />)
      }
    </ul>
  );
}

export default WhoToFollowList;