import React from 'react';

const History = () => {
  return (
      <div>
        <h2>Non React.js Assignments</h2>
        <h3>Assignment 2</h3>
        <ul>
          <li><a href='/a2/practice/index.html'>HTML Practice</a></li>
          <li><a href='/a2/twitter/navigation.html'>Twitter clone</a></li>
          <li><a href='/a2/practice/iframe/index.html'>Iframes</a></li>
        </ul>
        <h3>Assignment 3</h3>
        <ul>
          <li><a href='/a3/practice/css/index.html'>CSS Practice</a></li>
          <li><a href='/a3/twitter/navigation.html'>Twitter clone</a></li>
          <li><a href='/a3/twitter/explore/explore.html'>explore.html</a></li>
        </ul>
<h3>Assignment 4</h3>
        <ul>
          <li><a href='/a4/practice/bootstrap/index.html'>Bootstrap Practice</a></li>
          <li><a href='/a4/twitter/navigation.html'>Twitter clone</a></li>
          <li><a href='/a4/twitter/explore/explore.html'>explore.html</a></li>
        </ul>
        <h3>Assignment 5</h3>
        <ul>
          <li><a href='/a5/practice/js/index.html'>JavaScript & Console Practice</a></li>
          <li><a href='/a5/practice/todos/index.html'>ToDoList Practice</a></li>
          <li><a href='/a5/build/ExploreScreen/explore.html'>A5 Practice ExploreScreen</a></li>
        </ul>
      </div>
  )
}

export default History;