import React from 'react';
import NavigationSidebar from '../NavigationSidebar';
import TweetList from '../TweetList';
import PostSummaryList from '../PostSummary';
import WhatsHappening from '../WhatsHappening';

const HomeScreen = () => {
  return (
    <div className='container' id='wd-explore'>
      <div className='row mt-2'>
        <div className='col-2 col-md-2 col-lg-1 col-xl-2'>
          <NavigationSidebar active={'home'}/>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-9 col-sm-9'>
          <WhatsHappening />
          <TweetList />
        </div>
        <div className='d-sm-none d-md-none d-lg-block col-lg-4 col-xl-4'>
          <PostSummaryList title="What's Happening"/>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;