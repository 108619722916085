import React from 'react';
import { Link } from 'react-router-dom';
import Classes from './Classes';
import ConditionalOutput from './ConditionalOutput';
import Styles from './Styles';
import TodoList from './Todo/TodoList';


const Practice = () => {
  return (
    <>

      <br />
      <span style={{ color: 'gray' }}>
        A6&nbsp;&nbsp;
      </span>
      <Link to='/a6/hello'>
        Hello
      </Link> |
      <Link to='/a6/twitter/explore'>
        Build
      </Link>
      <h1>Practice</h1>
      <TodoList />
      <ConditionalOutput />
      <Styles />
      <Classes />

    </>
  )
};

export default Practice;