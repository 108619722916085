import React from 'react';
import { Link } from 'react-router-dom';
import ReduxExamples from './ReduxExamples';


const Practice = () => {
  return (
    <>
      <br />
      <span style={{color: 'gray'}}>
        A7&nbsp;&nbsp;
      </span>
      <Link to='/a7/twitter/explore'>
        Twitter
      </Link>
      <h1>Practice</h1>

      <ReduxExamples />
    </>
  )
};

export default Practice;