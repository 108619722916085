import React from 'react';
import { Link } from 'react-router-dom';
import APIExamples from './APIExamples';
import ReduxExamples from './ReduxExamples';


const Practice = () => {
  return (
    <>
      <br />
      <span style={{ color: 'gray' }}>
        A8&nbsp;&nbsp;
      </span>
      <Link to='/a8/twitter/explore'>
        Build
      </Link>
      <h1>Practice</h1>

      <APIExamples />
      <ReduxExamples />
    </>
  )
};

export default Practice;